<template>
    <div id="searchWrapper">
        <top-search></top-search>
        <div class="container" >
            <div class="row">
                <div class="col-md-12">
                    <div class="breadCrump">
                        <span>
                            <router-link to="/">
                                Startseite
                            </router-link>
                        </span>
                        |
                        <span>
                            Suchergebnisse
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h4 style="margin: 10px 0 15px;">Deine Suchergebnisse Für "{{searchParams.searchKey}}" ({{items.length}} Arbeiten)</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="searchFilter">
                        <div class="row">
                            <div class="col-md-12">
                                <select class="form-control" v-on:change="orderItems" v-model="orderValue">
                                    <option value="newest">Sortiert nach: Neuste zuerst</option>
                                    <option value="oldest">Sortiert nach: älteste zuerst</option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-md-12">Anzahl der Wörter:
                                <br>
                                <br>
                                <br>

                                <vue-range-slider
                                        :bg-style="bgStyle"
                                        :tooltip-style="tooltipStyle"
                                        :process-style="processStyle"
                                        :step="100"
                                        :min="sliderValueMin"
                                        :max="sliderValueMax"
                                        v-on:slide-end="filterLength()"
                                        v-model="lengthValue"
                                >

                                </vue-range-slider>
                            </div>
                        </div>
                        <div class="adsense">
                            <div class="row">
                                <div class="col-12">
                                    <Adsense v-if="adsense"
                                             data-ad-client="ca-pub-2696232756207990"
                                             data-ad-slot="8851516560">
                                    </Adsense>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="row" v-for="(item,index) in pageOfItems " :key="item.id_item">
                        <div class="adsense" v-if="index%2 == 0">
                            <div class="row">
                                <div class="col-12">
                                    <Adsense v-if="adsense"
                                             data-ad-format="horizontal"
                                             data-ad-client="ca-pub-2696232756207990"
                                             data-ad-slot="8851516560">
                                    </Adsense>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="articleBox">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h3>{{item.item_title | str_limit(45)}}</h3>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p>{{item.item_plaintext | str_limit(150)}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <table class="table detailInfos">
                                            <tr>
                                                <td>Von:</td>
                                                <td>{{item.author}}</td>
                                            </tr>
                                            <tr>
                                                <td>Fach:</td>
                                                <td>
                                                    {{item.class_1}}
                                                    <span v-if="item.class_2"> / {{item.class_2}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Kategorie:</td>
                                                <td>{{item.cluster}}</td>
                                            </tr>
                                            <tr>
                                                <td>Länge:</td>
                                                <td>{{item.content_length |  numFormat()}} Wörter</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-6 text-right" style="position: relative">
                                        <span class="btn btn-primary" style="position: absolute;bottom: 0px;right: 15px;}">
                                            <router-link :to="{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }">
                                                Arbeit ansehen
                                            </router-link>
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <jw-pagination :pageSize="5" :labels="customLabels" :items="items" @changePage="onChangePage"></jw-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InArticleAdsense)
    Vue.use(Ads.InFeedAdsense)

    const customLabels = {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
    };
    import TopSearch from "../components/TopSearch";
    import axios from "axios";
    import VueRangeSlider from 'vue-range-slider-component'
    import 'vue-range-slider-component/dist/vue-range-slider.css'
    export default {
        name: 'Search',
        components: { TopSearch, VueRangeSlider},
        data: function(){
            return{
                customLabels,
                adsense : false,
                sortKey: 'id_item',
                desc: true,
                searchReady: false,
                orderValue : "newest",
                searchParams: {},
                pageOfItems: [],
                lengthValue: [0, 100],
                items_origin: [],
                items: []
            }
        },
        methods:{
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            doSearch: function(){
                this.searchReady = false;
                axios({
                    method: 'post',
                    url: 'https://api.pausenhof.de/item/search',
                    headers: {'authorization': '3241451345'},
                    data: {
                        cluster: this.searchParams.searchCluster,
                        keyword: this.searchParams.searchKey
                    }
                }).then(response => {
                 this.items = response.data.content.data;
                 this.items_origin = response.data.content.data;
                 this.sliderValueMin =  response.data.content.min_length;
                 this.sliderValueMax =  response.data.content.max_length;
                 this.lengthValue =  [response.data.content.min_length,response.data.content.max_length];
                 this.searchReady = true;
                 var x = VueRangeSlider.methods.getValueByIndex(1);
                 console.log(x);
                });
            },
            orderItems: function () {
                if(this.orderValue == "oldest")
                    console.log(this.items.sort((a, b) => (a.date_create > b.date_create) ? 1 : -1));
                if(this.orderValue == "newest")
                    console.log(this.items.sort((a, b) => (a.date_create < b.date_create) ? 1 : -1));
            },
            filterLength: function () {
                this.items = [];
                var min = this.lengthValue[0];
                var max = this.lengthValue[1];
                for (var i in this.items_origin){
                    if(this.items_origin[i]['content_length'] >= min && this.items_origin[i]['content_length'] <= max ){
                        this.items.push(this.items_origin[i]);
                    }
                }
            }
        },
        created() {
            this.searchParams = localStorage.getItem("5234P34H_SKV");
            this.searchParams = JSON.parse(this.searchParams);
            console.log(this.searchParams);
            //localStorage.removeItem("5234P34H_SKV");
            this.doSearch();
            this.sliderValueMin = 0;
            this.sliderValueMax = 500;
            this.bgStyle = {
                backgroundColor: '#fff',
                boxShadow: 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.36)'
            }
            this.tooltipStyle = {
                backgroundColor: '#87cbf0',
                borderColor: '#87cbf0'
            }
            this.processStyle = {
                backgroundColor: '#87cbf0'
            }
            let cookieConsent=  Vue.$cookies.get("rephCookieCons");
            if(cookieConsent == '111') {
                this.adsense = true;
            } else if(cookieConsent == '101') {
                this.adsense = true;
            } else if(cookieConsent == '110') {
                this.adsense = false;
            }
        }
    }
</script>

<style scoped>
    .searchWrapper h1{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    .searchWrapper .table{
        margin-bottom: 0;
    }
    .articleBox{
        padding:10px 15px 15px;
        border: 1px solid #adadad;
        margin-bottom: 15px;
    }
    .searchFilter {
         background: #efefef;
         border: 1px solid #adadad;
         padding: 15px;
         box-shadow: 2px 2px 5px #9e9e9e;
         margin-bottom: 25px;
     }
    .detailInfos td{
        padding: 0px 0;
        border-top: none;
    }
    #searchWrapper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    #searchWrapper .btn-primary a{
        color:#fff !important;
    }
    #searchWrapper .btn-primary a:hover{
        text-decoration: none !important;
    }

</style>

